<template>

    <div class="w-full h-full module flex flex-col p-0">

        <div class="flex items-center mb-2 py-4 px-8 justify-start">

            <div class="h-full w-1/3 flex flex-row justify-start items-center">

                <div class="text-3xl text-font-gray cursor-pointer mr-auto"
                :style="'padding-top: 0.75rem; padding-left: 0.75rem'"
                @click="$router.push( { name: 'InterIntra' })">

                    <i class="p-2 text-font-aux capitalize flex items-center flex-none mdi mdi-arrow-left-thick
                        text-font-gray text-lg" :style="'font-style: normal'">
                        <span :style="'margin-left: 0.5rem;'">Return</span>
                    </i>

                </div>

            </div>

            <div class="h-full w-1/3 flex flex-col justify-center items-center">

                <div class="module-title self-center flex-none">
                    {{ $route.params.catId }}
                </div>

            </div>

            <div class="h-full w-1/3 flex flex-row justify-start items-center">

                <div class="h-12 rounded-md bg-body w-48 ml-auto flex flex-row">

                    <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch1 = 'Hours'">

                        <div class="h-full w-full flex flex-col justify-center items-center relative">

                            <span class="font-semibold z-20" :class="{'text-font-gray': switch1 != 'Hours', 'font-white': switch1 == 'Hours'}">Hours</span>

                            <div v-if="switch1 == 'Hours'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                        </div>

                    </div>

                    <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch1 = 'Fees'">

                        <div class="h-full w-full flex flex-col justify-center items-center relative">

                            <span class="font-semibold z-20" :class="{'text-font-gray': switch1 != 'Fees', 'font-white': switch1 == 'Fees'}">Fees</span>

                            <div v-if="switch1 == 'Fees'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                        </div>

                    </div>

                </div>

                <div class="h-12 rounded-md bg-body w-64 ml-4 flex flex-row">

                     <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch2 = 'Purchased'">

                        <div class="h-full w-full flex flex-col justify-center items-center relative">

                            <span class="font-semibold z-20" :class="{'text-font-gray': switch2 != 'Purchased', 'font-white': switch2 == 'Purchased'}">Purchased</span>

                            <div v-if="switch2 == 'Purchased'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                        </div>

                    </div>

                    <div class="h-full w-1/2 flex flex-col justify-center items-center relative p-2 cursor-pointer" @click="switch2 = 'Sold'">

                        <div class="h-full w-full flex flex-col justify-center items-center relative">

                            <span class="font-semibold z-20" :class="{'text-font-gray': switch2 != 'Sold', 'font-white': switch2 == 'Sold'}">Sold</span>

                            <div v-if="switch2 == 'Sold'" class="h-full w-full rounded-md bg-font-gray-15 absolute z-10 shadow-md"></div>

                        </div>

                    </div>

                </div>


            </div>

        </div>

        <separator />

        <div class="flex items-center h-full overflow-hidden">

            <div class="h-full w-full p-4 pl-8 flex flex-col overflow-hidden relative">

                <loader :loading="loaderSL"/>

                <div v-if="elementsSL.graph_sl_purchased && elementsSL.graph_sl_sold && elementsSL.graph_sl_purchased.length > 0 && elementsSL.graph_sl_sold.length > 0" class="h-full flex flex-col">

                    <div class="h-full overflow-hidden relative">
                        <div class="absolute top-0 left-0 text-3xl text-font-gray">SL</div>
                        <chart-dependency-wheel name="SL" v-if="switch1 == 'Hours' && switch2 == 'Sold'" :colors="colorsSL" :data="elementsSL.graph_sl_sold" :chartId="'SLChart'"/>
                        <chart-dependency-wheel name="SL" v-if="switch1 == 'Hours' && switch2 == 'Purchased'" :colors="colorsSL" :data="elementsSL.graph_sl_purchased" :chartId="'SLChart'"/>
                        <chart-dependency-wheel name="SL" v-if="switch1 == 'Fees' && switch2 == 'Sold'" :colors="colorsSL" :data="elementsSL.graph_sl_sold_fees" :chartId="'SLChart'"/>
                        <chart-dependency-wheel name="SL" v-if="switch1 == 'Fees' && switch2 == 'Purchased'" :colors="colorsSL" :data="elementsSL.graph_sl_purchased_fees" :chartId="'SLChart'"/>
                    </div>

                    <div class="box h-48">

                        <div class="h-full w-full flex flex-row p-2">

                            <div class="h-full w-1/4 flex-none flex flex-col">

                                <div class="h-14 "></div>

                                <div class="flex-1 min-h-0 flex flex-col justify-between items-start py-4">

                                    <span class="font-semibold">Own Engagement</span>
                                    <span v-if="switch2 == 'Purchased'" class="font-semibold">Purchased to others</span>
                                    <span v-if="switch2 == 'Sold'" class="font-semibold">Sold to others</span>

                                </div>

                            </div>

                            <div v-for="(el,index) in firstCat(dataTableSL)" :key="index" class="h-full w-full flex flex-row">

                                <div class="h-full w-full px-2 flex flex-col">

                                    <div class="h-14 flex flex-col justify-center items-center">
                                        <span class="font-semibold text-center text-font-gray" :style="'color:'">{{el[0]}}</span>
                                    </div>

                                    <div class="flex-1 min-h-0 flex flex-col justify-between items-center py-4">
                                        <span v-if="el[2] != '-'" class="font-semibold">{{el[2] | numberFormat(2)}}%</span>
                                        <span v-else class="font-semibold">-</span>
                                        <span v-if="switch2 == 'Purchased' && switch1 == 'Hours' && elementsSL.summary_sl.purchased_other[index]" class="font-semibold">{{elementsSL.summary_sl.purchased_other[index][2] | numberFormat(2)}}%</span>
                                        <span v-if="switch2 == 'Purchased' && switch1 == 'Fees' && elementsSL.summary_sl_fees.purchased_other[index]" class="font-semibold">{{elementsSL.summary_sl_fees.purchased_other[index][2] | numberFormat(2)}}%</span>
                                        <span v-if="switch2 == 'Sold' && switch1 == 'Hours' && elementsSL.summary_sl.sold_other[index]" class="font-semibold">{{elementsSL.summary_sl.sold_other[index][2] | numberFormat(2)}}%</span>
                                        <span v-if="switch2 == 'Sold' && switch1 == 'Fees' && elementsSL.summary_sl_fees.sold_other[index]" class="font-semibold">{{elementsSL.summary_sl_fees.sold_other[index][2] | numberFormat(2)}}%</span>
                                    </div>

                                </div>

                                    <separator v-if="index == 0" direction="vertical"/>

                            </div>

                        </div>

                    </div>

                </div>

                <div v-else-if="!loaderSL" class="h-full flex flex-col justify-center items-center">
                    <span class="module-title text-font-gray">No data</span>
                </div>

            </div>

            <separator :direction="'vertical'"/>

            <div class="h-full w-full  p-4 pr-8 flex flex-col overflow-hidden relative">

                <loader :loading="loaderSMU"/>

                <div v-if="elementsSMU" class="h-full flex flex-col">

                    <div v-if="smuUrl == 'inter_intra/graph_competency'" class="h-full overflow-hidden relative">
                        <div class="absolute top-0 left-0 text-3xl text-font-gray">SMU</div>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Hours'" :colors="smuColors" :data="elementsSMU.graph_competency" :chartId="'SMUChart'"/>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Fees'" :colors="smuColors" :data="elementsSMU.graph_competency_fees" :chartId="'SMUChart'"/>
                    </div>

                    <div v-else class="h-full overflow-hidden relative">
                        <div class="absolute top-0 left-0 text-3xl text-font-gray">SMU</div>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Hours' && switch2 == 'Sold'" :colors="colorsSL" :data="elementsSMU.graph_competency_sold" :chartId="'SMUChart'"/>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Hours' && switch2 == 'Purchased'" :colors="colorsSL" :data="elementsSMU.graph_competency_purchased" :chartId="'SMUChart'"/>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Fees' && switch2 == 'Sold'" :colors="colorsSL" :data="elementsSMU.graph_competency_fees" :chartId="'SMUChart'"/>
                        <chart-dependency-wheel name="SMU" v-if="switch1 == 'Fees' && switch2 == 'Purchased'" :colors="colorsSL" :data="elementsSMU.graph_competency_purchased_fees" :chartId="'SMUChart'"/>
                    </div>

                    <div class="box h-48">

                        <div class="h-full w-full flex flex-row p-2">

                            <div v-for="(el,index) in dataTableSMU" :key="index" class="h-full w-full px-2 flex flex-col">

                                <div class="h-14 flex flex-col justify-center items-center">
                                    <span class="font-semibold text-center text-font-gray" :style="'color:'">{{el[0]}}</span>
                                </div>

                                <div class="flex-1 min-h-0 flex flex-col justify-between items-center py-4">
                                    <span class="font-semibold">{{el[2] | numberFormat(2)}}%</span>
                                    <span v-if="switch2 == 'Purchased'" class="font-semibold">{{elementsSMU.summary_competency.purchased_other[index][2] | numberFormat(2)}}%</span>
                                    <span v-else class="font-semibold">{{elementsSMU.summary_competency.sold_other[index][2] | numberFormat(2)}}%</span>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <div v-else class="h-full flex flex-col justify-center items-center">
                    <span class="module-title text-font-gray">No data</span>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import {state } from '@/store'

    export default {
        data() {
            return {
                sortBy: null,
                elementsSL: {},
                elementsSMU: {},
                smuElements: [
                    'ADV FSO Actuarial',
                    'ADV FSO Staff Pool',
                    'ADV FSO PI',
                    'ADV FSO Itra',
                    'ADV FSO PI Customer',
                    'ADV FSO FSRM',
                ],
                pills: [ 'ass','adv','pas','tas'],
                colorsSL: ['#0ACFFE', '#CE7CC5', '#4F65E0', '#569A38', '#DEAA8F'],
                smuColors: ['#0ACFFE'],
                options: ['Hours','Fees'],
                switch1:'Hours',
                switch2:'Purchased',
                loaderSL: true,
                loaderSMU: true
            }
        },
        computed: {
            user(){ 
                return state.user 
            },
            params(){ 
                return state.globalParams 
            },
            salesVsLimit(){ 
                return this.salesPercent - this.limit 
            },
            catid(){ 
                return this.$route.params.catId 
            },
            dataTableSL(){

                if(Object.keys(this.elementsSL).length > 0) {
                    if(this.switch1 == 'Hours'){

                        if(this.switch2 == 'Purchased'){

                            return this.elementsSL.summary_sl.purchased

                        } else {

                            return this.elementsSL.summary_sl.sold

                        }

                    } else {

                        if(this.switch2 == 'Purchased'){

                            return this.elementsSL.summary_sl_fees.purchased

                        } else {

                            return this.elementsSL.summary_sl_fees.sold

                        }

                    }
                }

                return [];

            },
            dataTableSMU(){

                if(Object.keys(this.elementsSMU).length > 0) {

                    if(this.switch1 == 'Hours'){

                        if(this.switch2 == 'Purchased'){

                            return this.elementsSMU.summary_competency.purchased

                        } else {

                            return this.elementsSMU.summary_competency.sold

                        }

                    } else {

                        if(this.switch2 == 'Purchased'){

                            return this.elementsSMU.summary_competency_fees.purchased

                        } else {

                            return this.elementsSMU.summary_competency_fees.sold

                        }

                    }

                }

                return [];

            },
            smuUrl(){

                if((state.globalParamsPretty.rol.role_type_link == 'competencyLeader') || (state.globalParams.competencyIdWithRegionId && state.globalParams.competencyIdWithRegionId.length > 0)){
                    return 'inter_intra/graph_competency_with_filter'
                } else {
                    return 'inter_intra/graph_competency'
                }

            },
            path(){
                return this.$route.path.split('/')[2]
            }
        },
        methods: {
            idSL(){ 
                return this.$route.params.idSL 
            },
            getSL() {
                this.loaderSL = true
                this.axios.get('inter_intra/graph_sl', { params: { ...this.params, sl_zeus: [this.idSL()] }})
                    .then(response => {
                    this.elementsSL = response.data.data.graph_sl;
                    this.loaderSL = false
                })
            },
            getSMU() {
                this.loaderSMU = true
                this.axios.get(this.smuUrl, { params: { ...this.params, sl_zeus: [this.idSL()] }})
                    .then(response => {
                        this.elementsSMU = response.data.data.graph_competency
                        this.loaderSMU = false
                    })
            },
            firstCat(list){

                let result = []

                for (let index = 0; index < list.length; index++) {

                    if(list[index][0] == this.catid){
                        result.push(list[index])
                    }

                }

                for (let index = 0; index < list.length; index++) {

                    if(list[index][0] != this.catid){
                        result.push(list[index])
                    }

                }

                return result

            }
        },
        mounted() {
            this.getSL()
            this.getSMU()
        },
        watch: {
            params(n, o) {
                this.getSL()
                this.getSMU()
            }
        },
    }
</script>